html {
  font-size: 14px !important; /* Adjust base font size for scaling */
  width: 100%;
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  position: relative;
  background-color: #f7f7f7;
}

html,
body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100% !important;
}

.area-footer {
  background: #eb0a1e;
  text-align: center;
  color: #f7f7f7;
  padding: calc(10px * 0.8) 0;
  padding: 4px 0;
  height: auto;
  width: calc(100% - 240px);
  right: 0;
  bottom: 0;
  margin: 0;
  position: fixed;
}

.area-footer p {
  width: calc(200px * 0.8);
  margin: 0 auto;
}
body > .area-footer {
  margin-top: auto;
}
.text-required,
[required] {
  background-color: #ffe1e4 !important;
  border-radius: 4px;
}

.custom-cell-table {
  margin: calc(10px * 0.8) calc(2px * 0.8) !important;
  min-height: calc(40px * 0.8) !important;
  border-radius: calc(5px * 0.8);
  border: 1px solid #c4adaf !important;
}

.MuiBox-root.css-1n01cdn {
  margin-top: 4rem !important;
  margin-left: 17rem !important;
  margin-right: 0rem !important;
  margin-bottom: 10rem !important;
}

.MuiFormControl-root.MuiFormControl-marginDense.MuiTextField-root {
  margin-bottom: calc(8px * 0.8);
}

.MuiPopover-root.MuiMenu-root.MuiModal-root {
  margin-top: 4px;
  transform-origin: top left;
  max-height: 60% !important;
  max-width: 75% !important;
  .MuiButtonBase-root.MuiMenuItem-root {
    max-width: 100% !important;
  }
}

.MuiSelect-select {
  position: relative;
}

.MuiList-root {
  top: 1px;
  right: 1px;
  position: absolute;
}

.hide-columns {
  color: transparent;
  height: 0 !important;
  width: calc(20px * 0.8) !important;
  min-width: calc(20px * 0.8) !important;
  max-width: calc(20px * 0.8) !important;
}

.MuiDataGrid-overlay {
  display: none !important;
}

[data-field="price"] .MuiDataGrid-cellContent {
  white-space: pre-line;
}

.align-items-end .MuiDataGrid-columnHeaderDraggableContainer {
  align-items: end;
}

.table-header-group {
  border-bottom: 1px solid #ffff;
}

[data-field="price"] .MuiDataGrid-cellContent {
  color: blue;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Hidden Place Holder on type Date */
input[type="date"]:in-range::-webkit-datetime-edit-year-field,
input[type="date"]:in-range::-webkit-datetime-edit-month-field,
input[type="date"]:in-range::-webkit-datetime-edit-day-field,
input[type="date"]:in-range::-webkit-datetime-edit-text {
  color: transparent;
}

#table_upload {
  border-collapse: collapse;
  width: 100%;
  margin-top: calc(25px * 0.8);
}

#table_upload,
td,
th {
  border: 1px solid rgb(163, 163, 163);
  padding: calc(6px * 0.8);
}

#table_upload thead {
  background-color: #7f7f7f;
  color: #ffff;
}

.table-container {
  max-width: 100%;
  overflow-x: auto;
  border: 1px solid #7f7f7f;
  min-height: calc(400px * 0.8);
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container thead {
  background-color: #7f7f7f;
  color: #ffff;
}

.table-container th,
td {
  padding: calc(12px * 0.8);
  border: 1px solid rgb(163, 163, 163);
  text-align: left;
}

.table-container tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.input-table-border {
  border: 2px solid black;
}

/* Start Style Sidebar */

.css-10dglf0 {
  margin-left: 0px !important;
}

.css-1fmjyqb-MuiTypography-root {
  font-size: 14px !important;
}

.css-1f5tiam-MuiButtonBase-root-MuiListItemButton-root {
  padding: 0px 8px 0px 15px !important;
}

#root
  > div.MuiBox-root.css-kzfr2u
  > div
  > div
  > div.MuiBox-root.css-169sew0
  > ul
  > ul
  > div.MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered.css-pwcg7p-MuiCollapse-root
  > div
  > div
  > div
  > ul.MuiList-root.MuiList-dense.css-npvrsk-MuiList-root
  > div
  > div {
  margin-left: 15px !important;
}

.css-1jrsixg-MuiListItemText-root {
  margin-left: 16px !important;
}

.text-field-style {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.text-field-label {
  min-width: 140px;
}

.text-field-style {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
}

.text-field-label {
  min-width: 140px;
}

/* End Style Sidebar */
