.area-row-header {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #cec5c5;
    background-color: #ededed;
}

.btn-close {
    color: blue;
    cursor: pointer;
}

.box {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    padding: 0;
}

.box .sub-box { 
    flex: 1
}

.box table {
    width: 100%;
    border-collapse: collapse;
}

.box table tr td {
    border: 1px solid #cdcdcd;
    padding: 5px;
}

img.preview {
    width: 380px;
    height: 400px;
}

.bg-light-gray {
    background-color: #f1f1f1;
}