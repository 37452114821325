.box {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 10px;
    justify-content: space-between;
}
.box-left, .box-right {
    width: 45%;
    border: 1px solid #cec5c5;
    border-radius: 6px;
    padding: 12px;
}

.list-data, .list-data-seleted {
    padding: 0;
    margin: 0;
    list-style: none;
    max-height: 275px;
    overflow: auto; }

.list-data > li {
    display: flex;
    justify-content: center;
    align-items: center;
}

.list-data > li > div {
    width: 50%;
    text-align: center;
    padding: 8px 2px;
    margin-left: 2em;
}

.head {
    display: flex;
    justify-content: space-around;
    border: 1px solid lightgray;
    padding: 8px 0px;
    color:#FFFFFF;
    font-family: "Times New Roman", Times, serif;
	font-weight: bold;
    background-color: #7F7F7F;
    margin-bottom: 4px;
    margin-right:4px ;
}


.list-data-seleted > li.head {
    justify-content: space-around;
    border: 1px solid lightgray;
    padding: 8px 0px 8px 20px;
}

.list-data-seleted > li {    
    display: grid;
    grid-template-columns: auto auto auto;
    align-items: center;
}

.list-data > li:nth-child(odd),
.list-data-seleted > li:nth-child(odd) {
    background-color: #efeeee;
}

.list-data > li:nth-child(even),
.list-data-seleted > li:nth-child(even) {
    background-color: #f6f6f6;
}

.area-data-selected {
    display: flex;
    align-items: center;
    width:  100%;
    justify-content: space-around;
}
.area-data-selected > div {
    width:100px;

}

.icon-delete {
    cursor: pointer;
    text-align: center;
}

.btn-close {
    font-size: 1em;
    font-weight: bold;
    border-radius: 5px 5px;
    max-width: 50px;
    max-height: 70px;
    text-align: center;
}
.btn-close:hover {
    background-color:#C4C4C4;
}