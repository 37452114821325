.table-container-group {
    position: relative;
    border-collapse: collapse;
    text-align: left;
}

.table-container-group th {
    position: sticky;
    top: 0; 
}

.table-container-group thead tr td {
    padding: 8px;
}

.table-container-group tbody tr td {
    padding: 0 14px;
}

.table-container-fixed {
    display: block;
    height: 350px;
    overflow-y: scroll;
}

.table-container-fixed thead tr {
    position: sticky;
    color: #ffff;
    background-color: #7F7F7F;
    top: 0;
    z-index: 119;
 }
 
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}